import React from 'react';

import Layout from '../components/layout/layout';
import Seo from '../components/layout/seo';

const NotFoundPage = () => (
	<Layout>
		<Seo title='404: Not found' />
		<div className='flex flex-col text-center max-w-sm px-6 py-20 md:px-12 md:py-28 md:max-w-2xl'>
			<div className='text-9xl font-title text-ap-purple'>404</div>
			<div className='text-2xl md:text-3xl font-title pt-12 pb-8'>
				Uh-oh! Something doesn’t look right here.
			</div>
			<div className='text-lg mb-8 font-body'>
				The page you requested could not be found. You may have mistyped the
				address or the page may have moved. Please check the address and try
				again.
			</div>
			<div className='mt-6'>
				<a
					href='/'
					className='py-4 px-6 text-base leading-6 font-medium rounded-md text-white bg-ap-purple hover:bg-ap-purple-dark focus:outline-none transition duration-150 ease-in-out'>
					Back to Homepage
				</a>
			</div>
		</div>
	</Layout>
);

export default NotFoundPage;
